import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/button";
import LogoName from "../../../components/LogoName/logoName";
import { APP_ROUTES } from "../../../router/Route";
import styles from "./welcome.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store";
import { baseUrl } from "../../../contants/API";
import {
  LogoutForFirstPage,
  LogoutProject,
  useFaceIdLogin,
} from "../../../hook/useFaceIdLogin";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Carousel from "nuka-carousel";
import { useIdleTimer } from "react-idle-timer";

const WelcomeScreen = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch<Dispatch>();
  const { logout } = useSelector((state: RootState) => state.profileSlice);
  const branch_id = localStorage.getItem("branch_id");

  const fetchSliderData = () => {
    const objectId = localStorage.getItem("object_id");

    switch (objectId) {
      case "99":
      case "13":
      case "14":
      case "23":
        dispatch.OtherSlice.getSliderNotToken("1");
        break;
      case "2":
        dispatch.OtherSlice.getSliderNotToken("8");
        break;
      case "3":
        dispatch.OtherSlice.getSliderNotToken("7");
        break;
      default:
        dispatch.OtherSlice.getSliderNotToken("12");
        break;
    }
  };

  useEffect(() => {
    fetchSliderData();
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      dispatch.profileSlice.setLogout(false);
    }, 5000);
  }, []);

  LogoutForFirstPage();

  const { sliderListNotToken } = useSelector(
    (state: RootState) => state.OtherSlice
  );

  const handleOnUserIdle = () => {
    if (pathname === APP_ROUTES.BANNER) navigation(APP_ROUTES.WELCOME);
  };

  useIdleTimer({
    timeout: 5000,
    onIdle: handleOnUserIdle,
    debounce: 500,
  });

  return (
    <>
      {logout && (
        <div
          className={styles.popup}
          onClick={() => dispatch.profileSlice.setLogout(false)}
        >
          <div className={styles.modal}>
            <p>До свидания!</p>
            <p>Ждем вас снова!</p>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.logoBox}>
          <LogoName fontSize="170px" />
        </div>
        <div className={styles.bannerBox}>
          <div className={styles.cameraButtonsEnter}>
            <Button
              btnSize="large"
              btnType="outline"
              title="Вход"
              style={{
                marginTop: "-50px",
                height: "70px",
              }}
              onPress={() => {
                dispatch.authSlice.clickForLogin();
                navigation(APP_ROUTES.FACE_ID);
              }}
            />
            <Button
              btnSize="large"
              btnType="primary"
              title="Регистрация"
              style={{
                marginTop: "-50px",
                height: "70px",
              }}
              onPress={() => navigation(APP_ROUTES.LOGIN)}
            />
          </div>

          <div className={styles.containerSlider}>
            <Carousel autoplay={true} autoplayInterval={5000}>
              {sliderListNotToken?.map((item) => (
                <img
                  key={item.id}
                  onClick={() => navigation(APP_ROUTES.BANNER)}
                  style={{
                    height: "280px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={`${baseUrl}/${item.path}`}
                  alt={`${baseUrl}/${item.path}`}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeScreen;
