import React, { useEffect } from "react";
import Modal from "react-modal";
import styles from "./order.module.css";
import useRootStore from "../../hook/useRootStore";
import Title from "../Title/title";
import { observer } from "mobx-react-lite";
import { Backdrop } from "@mui/material";
import InfoComp from "../InfoComp/infoComp";
import ItemComp from "../ItemComp/itemComp";
import { COLORS } from "../../constants/colors";
import { ArrowRight, Cash, Exit, Logout } from "../../assets/icons";
import { ASSETS } from "../../constants/requireAssets";
import OrderList from "../OrderList/orderList";
import Button from "../Button/button";
import SelectComp from "../SelectComp/selectComp";
import { Dispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import IconComp from "../IconComp/iconComp";
import { useNavigate, useNavigation } from "react-router-dom";
import { APP_ROUTES } from "../../router/Route";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Carousel from "nuka-carousel";

import { baseUrl } from "../../contants/API";

const Order = () => {
  const branch_id = localStorage.getItem("branch_id");
  const { visiable, show, hide } = useRootStore().visiibleStore;
  const BackBasket = () => {
    show("basket");
    hide("order");
  };

  const { priceList } = useSelector((state: RootState) => state.basketSlice);

  const { userList } = useSelector((state: RootState) => state.profileSlice);

  const navigation = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const success = () => {
    toast.success("Спасибо за покупку!", {
      autoClose: 2000,
    });
  };

  const logout = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("showedInstructions");
    
    localStorage.removeItem("@checkToken");
    dispatch.profileSlice.logout();
    navigation(APP_ROUTES.WELCOME);
    success();
  };

  useEffect(() => {
    if (branch_id) {
      dispatch.OtherSlice.getSliderNotToken(branch_id);
    }
  }, [branch_id]);

  const { sliderListNotToken } = useSelector(
    (state: RootState) => state.OtherSlice
  );
  return (
    <div
      className={styles.container}
      style={{ display: visiable.order ? "flex" : "none" }}
    >
      <Backdrop
        open={visiable.order}
        // onClick={() => hide("order")}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.leftBox}>
              <div className={styles.nameBox}>
                <div
                  className={styles.headerId}
                  style={{ display: "flex", fontSize: "26px" }}
                >
                  ID:{" "}
                  <span
                    style={{ color: "red", margin: "0px 15px 0 5px" }}
                    className={styles.numberId}
                  >
                    {userList?.id}
                  </span>
                  <Title
                    title={`${userList.full_name}` || ""}
                    fontSize="26px"
                  />
                </div>
              </div>
              <Title title="Оформление заказа" fontSize="24px" />

              {/* <div className={styles.center}>
                <Title title="Адрес доставки" fontSize="15px" />
                <SelectComp />
              </div> */}
            </div>

            <div className={styles.right} style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.5rem",
                  padding: "15px 30px",
                }}
              >
                <ItemComp
                  icon={<Cash />}
                  title="Остаток денежных средств:"
                  text={
                    `${priceList.balance?.toLocaleString("ru-RU")} сум` || ""
                  }
                  textColor={COLORS.orange}
                />
                <ItemComp
                  icon={<Cash />}
                  title="Остаток по лимиту:"
                  text={
                    `${priceList.limit_summa?.toLocaleString("ru-RU")} сум` ||
                    ""
                  }
                  textColor={COLORS.orange}
                />
              </div>
              {/* <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%,-50%)",
                  color: "#fff",
                  fontSize: "32px",
                }}
              >
                Реклама
              </div> */}
              {/* <Carousel autoplay={true}>
                {sliderListNotToken.map((item) => (
                  <>
                    <img
                      onClick={() => navigation(APP_ROUTES.BANNER)}
                      src={`${baseUrl}/${item.path}`}
                    />
                  </>
                ))}
              </Carousel> */}
            </div>
          </div>
          <div className={styles.listBox}>
            <OrderList />
            <div className={styles.total}>
              <Title
                title="Итого:"
                fontSize="20px"
                style={{ marginRight: "10px" }}
              />
              <Title
                title={`${priceList.total_price?.toLocaleString("ru-RU")} сум`}
                fontSize="20px"
                color={COLORS.orange}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.btnBox}>
              {/* <IconComp
                iconType="primary"
                icon={<ArrowRight />}
                text="Выйти из системы"
              /> */}

              <Button
                btnSize="large"
                btnType="outline"
                style={{
                  marginRight: "20px",
                  width: "350px",
                  marginLeft: "auto",
                }}
                title="Выйти из системы"
                onPress={() => logout()}
              />
              <Button
                style={{
                  marginRight: "20px",
                  width: "350px",
                  marginLeft: "auto",
                }}
                btnSize="large"
                btnType="outline"
                title="В общий магазин"
                onPress={() => {
                  hide("order");
                  navigation(APP_ROUTES.MARKET);
                }}
              />
              <Button
                style={{ marginRight: "20px", width: "350px" }}
                btnSize="large"
                btnType="outline"
                title="Назад в корзину"
                onPress={() => BackBasket()}
              />
              <Button
                style={{ width: "350px" }}
                btnSize="large"
                btnType="primary"
                title="Заказать"
                onPress={() => {
                  show("confirmOrder");
                  toast.success(
                    'Чек-информация будет доступна только в окне "ИСТОРИЯ ТРАНЗАКЦИЙ"!',
                    {
                      autoClose: 15000,
                    }
                  );
                  toast.success(
                    'Сизнинг буюртмангиз хакидаги чек-маълумотлари фақат "ИСТОРИЯ ТРАНЗАКЦИЙ" ойнасида мавжуд бўлади!',
                    {
                      autoClose: 15000,
                    }
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default observer(Order);
