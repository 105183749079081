import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/button";
import Input from "../../../components/Input/input";
import LogoName from "../../../components/LogoName/logoName";
import Title from "../../../components/Title/title";
import { APP_ROUTES } from "../../../router/Route";
import styles from "./login.module.css";
import { $api } from "../../../contants/API";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-simple-keyboard/build/css/index.css";
import { useIdleTimer } from "react-idle-timer";
import NumPad from "../../../components/NumPad/NumPad";
import { set } from "lodash";

const Login = () => {
  const navigation = useNavigate();
  const [idValue, setIdValue] = useState("");
  const [passValue, setPassValue] = useState("");
  const [focusedInput, setFocusedInput] = useState("id");
  const [showNumericKeyboard, setShowNumericKeyboard] = useState(false);
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [isActiveRegisterBtn, setIsActiveRegisterBtn] = useState(false);
  const [isActiveCheckBtn, setIsActiveCheckBtn] = useState(true);
  const location = useLocation();
  const pathname = location.pathname;

  const getUser = async () => {
    try {
      const token = localStorage.getItem("@checkToken");
      const api = "https://api.smart-dunyo.uz/api/v1/user/info";
      const data = {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(api, data);
      const result = await response.json();
      return result;
    } catch (e) {}
  };

  const handleOnUserIdle = () => {
    if (pathname === APP_ROUTES.SIGNIN) navigation(APP_ROUTES.WELCOME);
  };

  useIdleTimer({
    timeout: 20000,
    onIdle: handleOnUserIdle,
    debounce: 1000,
  });

  const handleNumericButtonClick = (value: string) => {
    if (idValue.length > 7 || passValue.length > 7) return;

    if (focusedInput === "id") {
      setIdValue(idValue + value);
    } else {
      setPassValue(passValue + value);
    }
  };

  const handleBackspace = () => {
    if (focusedInput === "id") {
      setIdValue(idValue.slice(0, -1));
    } else {
      setPassValue(passValue.slice(0, -1));
    }

    if(isActiveRegisterBtn === false){
      setIsActiveCheckBtn(true);
      setIsActiveRegisterBtn(false);
    } else {
      setIsActiveCheckBtn(true);
      setIsActiveRegisterBtn(false);
      setName("");
    }
  };

  const signin = () => {
    const api = "https://api.smart-dunyo.uz/api/v1/user/sign-in";
    const data = {
      id: idValue,
      password: passValue,
    };

    fetch(api, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        localStorage.setItem("@checkToken", data.data);
        if (data.success) {
          const userInfo = await getUser();
          const userObjectId = userInfo.branch_name
            .slice(0, 2)
            .replace(/\D/g, "");
          if (userObjectId === localStorage.getItem("object_id")) {
            setUserName(userInfo.username.toLowerCase());
            setName(userInfo.full_name);
            setIsActiveRegisterBtn(true);
            setIsActiveCheckBtn(false);
          } else {
            toast.error(`Вы АКТИВНЫ в ${userInfo.branch_name} колонии!`, {
              autoClose: 20000,
            });
            toast.error(
              `Обратитесь к своему бухагалтеру для перевода в эту колонию!`,
              {
                autoClose: 20000,
              }
            );
          }
        } else {
          setName("");
          setIsActiveRegisterBtn(false);
          setIsActiveCheckBtn(true);
          setUserName("");
          toast.error("Неверный логин или пароль", {
            autoClose: 10000,
          });
          toast.error("Или вы НЕ АКТИВНЫ", {
            autoClose: 10000,
          });
          toast.error("Если вы НЕ АКТИВНЫ, обратитесь к своему бухагалтеру!", {
            autoClose: 10000,
          });
        }
      });
  };

  const error = () => {
    toast.error("Ошибка авторизации", {
      autoClose: 1500,
    });
  };
  const dataSend = async (payload: any) => {
    try {
      const data = await $api.post("v1/user/login", payload);
      navigation(APP_ROUTES.FACE_ID_REG);
    } catch (e) {
      error();
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <div className={styles.container}>
        <div className={styles.topBox}>
          <LogoName />
          <Title title="Регистрация" />
        </div>
        <div className={styles.bottomBox}>
          <Input
            label="ID"
            placohlder="Введите свой ID"
            valueInput={idValue}
            onClick={() => {
              setShowNumericKeyboard(true);
              setFocusedInput("id");
            }}
          />
          <Input
            label="Пароль"
            placohlder="Введите свой пароль"
            valueInput={passValue}
            type="text"
            onClick={() => {
              setShowNumericKeyboard(true);
              setFocusedInput("password");
            }}
          />
          <div className={styles.usersNameContainer}>
            <p>{name}</p>
          </div>
          <div className={styles.registerBtns}>
            <Button
              btnSize="large"
              btnType="primary"
              title={"Проверить"}
              style={{ width: "100%", marginTop: "20px" }}
              onPress={signin}
              disabled={!isActiveCheckBtn}
            />
            <Button
              btnSize="large"
              btnType="primary"
              title={"Регистрация"}
              style={{ width: "100%", marginTop: "20px" }}
              onPress={() => {
                dataSend({
                  username: userName,
                  password: passValue,
                  token: localStorage.getItem("cameraToken"),
                });
              }}
              disabled={
                !isActiveRegisterBtn &&
                (passValue.length < 8 || idValue.length < 8)
              }
            />
          </div>
          <Button
            btnSize="large"
            btnType="outline"
            title="Выход"
            style={{
              marginTop: "30px",
            }}
            onPress={() => navigation(APP_ROUTES.WELCOME)}
          />
        </div>
        {showNumericKeyboard ? (
          <NumPad
            onButtonClick={handleNumericButtonClick}
            onBackspace={handleBackspace}
          />
        ) : null}
      </div>
    </>
  );
};

export default Login;

