import React from "react";
import LogoName from "../../../components/LogoName/logoName";
import Title from "../../../components/Title/title";
import styles from "./faceId.module.css";

import Button from "../../../components/Button/button";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../router/Route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFaceIdRegister } from "../../../hook/useFaceIdRegister";

const FaceIdRegSuccess = () => {
  const navigation = useNavigate();
  useFaceIdRegister();
  return (
    <div className={styles.container}>
      <LogoName />
      <ToastContainer position="top-center" />
      <Title fontSize="35px" title="Вы успешно зарегистрировались." />
      <Title fontSize="35px" title="Нажмите ВХОД для того чтобы войти." />
      <Button
        btnSize="large"
        btnType="outline"
        title="Вход"
        style={{
          marginTop: "30px",
        }}
        onPress={() => navigation(APP_ROUTES.FACE_ID)}
      />
    </div>
  );
};

export default FaceIdRegSuccess;
