import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../store";
import { $api } from "../contants/API";
import { APP_ROUTES } from "../router/Route";
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useIdleTimer } from "react-idle-timer";

export const useFaceIdLogin = () => {
  const navigation = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const tokenForSocet = localStorage.getItem("cameraToken");
  console.log(tokenForSocet);

  const socket = useRef<WebSocket>(
    new WebSocket(`wss://smart-dunyo.uz/socket?token=${tokenForSocet}`)
  );

  const getUserInfo = async () => {
    try {
      const token = localStorage.getItem("@token");
      const api = "https://api.smart-dunyo.uz/api/v1/user/info";
      const data = {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(api, data);
      const result = await response.json();
      return result;
    } catch (e) {}
  };

  useEffect(() => {
    socket.current.onmessage = async (data: any) => {
      const token = JSON.parse(data.data).data.auth_key;
      localStorage.setItem("@token", token);
      $api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      if (token) {
        const userInfo = await getUserInfo();
        const userObjectId = userInfo.branch_name
          .slice(0, 2)
          .replace(/\D/g, "");

        if (userObjectId === localStorage.getItem("object_id")) {
          navigation(APP_ROUTES.MAIN);
          dispatch.profileSlice.getUser();
        } else {
          toast.error(`Вы АКТИВНЫ в ${userInfo.branch_name} колонии!`, {
            autoClose: 20000,
          });
          toast.error(
            `Обратитесь к своему бухагалтеру для перевода в эту колонию!`,
            {
              autoClose: 20000,
            }
          );
        }
      } else {
        toast.error("Вы НЕ АКТИВНЫ",{
          autoClose: 10000,
        });
        toast.error("Обратитесь к своему бухагалтеру!",{
          autoClose: 10000,
        });
      }
      socket.current.close();
    };
  }, []);
};

export const LogoutProject = () => {
  const dispatch = useDispatch();

  const navigation = useNavigate();

  const handleOnUserIdle = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("showedInstructions");
    
    localStorage.removeItem("@checkToken");
    dispatch.profileSlice.logout();
    navigation(APP_ROUTES.WELCOME);
  };
  // useIdleTimer({
  //   timeout: 20000,
  //   onIdle: handleOnUserIdle,
  //   debounce: 500,
  // });
};

export const LogoutForFirstPage = () => {
  const dispatch = useDispatch();

  const navigation = useNavigate();

  const handleOnUserIdle = () => {
    navigation(APP_ROUTES.WELCOME);
  };
  // useIdleTimer({
  //   timeout: 20000,
  //   onIdle: handleOnUserIdle,
  //   debounce: 500,
  // });
};

export const LogoutForMainAndMarketPage = () => {
  const dispatch = useDispatch();

  const navigation = useNavigate();

  const handleOnUserIdle = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("showedInstructions");
    
    localStorage.removeItem("@checkToken");
    dispatch.profileSlice.logout();
    navigation(APP_ROUTES.BANNER);
  };
  // useIdleTimer({
  //   timeout: 20000,
  //   onIdle: handleOnUserIdle,
  //   debounce: 500,
  // });
};
