import { useEffect, useRef } from "react";
import "./App.css";
import Router from "./router/router";
import io, { Socket } from "socket.io-client";
// localStorage.setItem("@token", "kqnWyfzZJLoqksrOF0cXUJfKKSt55WMB");
// localStorage.setItem("@token", "bsuFxc_4KyEQCtpUB8VtQpQlU4i3JabD");
// localStorage.setItem("@token", "35ID9DXfcWf7C7KQX_MQupQq-CO0YtJW");

function App() {
  const requestOptions: RequestInit = {
    method: "GET",
  };

  function sendDataToCheckIfWorking() {
    const objectNumber = localStorage.getItem("object_id");
    const monitorNumber = localStorage.getItem("monitor_id");

    if (!objectNumber || !monitorNumber)
      return console.log("objectNumber or monitorNumber is not defined");

    fetch(
      `https://monoblock.smart-dunyo.uz/add/${objectNumber}/${monitorNumber}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      sendDataToCheckIfWorking();
    }, 3600000);

    return () => clearInterval(interval);
  }, []);
  
  return <Router />;
}

export default App;
