import { observer } from "mobx-react-lite";
import React, { Dispatch, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowRight,
  CaseLarge,
  Change,
  Mobile,
  PasswordIcon,
  BirthdayIcon,
  Person,
  VideoCall,
  Warn,
} from "../../../../assets/icons";
import Button from "../../../../components/Button/button";
import Card from "../../../../components/Card/card";
import IconComp from "../../../../components/IconComp/iconComp";
import InfoComp from "../../../../components/InfoComp/infoComp";
import Title from "../../../../components/Title/title";
import { COLORS } from "../../../../constants/colors";
import { APP_ROUTES } from "../../../../router/Route";
import styles from "./main.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LogoutForMainAndMarketPage } from "../../../../hook/useFaceIdLogin";
import BranchModal from "../../../../components/branchModal/branchModal";
import ModalPassword from "../../../../components/modalPassword/modalPassword";
import ModalBirthday from "../../../../components/modalBirthday/modalBirthday";

const MainView = () => {
  const navigation = useNavigate();
  const [branchModal, setBranchModal] = useState<Boolean>(false);
  const [modalPassword, setModalPassword] = useState<Boolean>(false);
  const [modalBirthday, setModalBirthday] = useState<Boolean>(false);
  const [isCorrectPass, setIsCorrectPass] = useState<Boolean>(true);
  const [isBirthday, setIsBirthday] = useState<Boolean>(true);
  const [objectId, setObjectId] = useState<any>("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.profileSlice.getUser();
    dispatch.basketSlice.getAddCard();
    dispatch.OtherSlice.getShops();
    setObjectId(localStorage.getItem("object_id"));
  }, []);

  const { userList } = useSelector((state: RootState) => state.profileSlice);
  const { priceList } = useSelector((state: RootState) => state.basketSlice);

  useEffect(() => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    const fullDate = `${dd}-${mm}-${yyyy}`;

    setTimeout(() => {
      dispatch.orderSlice.isResultFunction(false);
    }, 5000);

    const waitForUserInfo = async () => {
      const userInfo = await dispatch.profileSlice.getUser();

      const storedPassword = userInfo?.password;
      const userBirthday = userInfo?.birthday;

      const showToast = (message: any) => {
        toast.success(message, { autoClose: 10000 });
      };

      if (!localStorage.getItem("showedInstructions") && storedPassword && storedPassword.length < 8) {
        showToast("Илтимос, ПАРОЛИНГИЗНИ ўзгартиринг ва эсда тутинг!");
        showToast("Просим вас изменить и запомнить свой пароль!");
        setIsCorrectPass(false);
        setModalPassword(true);
      } else if (modalPassword === false && (!userBirthday || userBirthday === "-" || userBirthday === fullDate)) {
        showToast("Илтимос, ТУГИЛГАН КУНИНГИЗНИ КИРИТИНГ!");
        showToast("Пожалуйста, введите свой день рождения!");
        setIsBirthday(false);
        setModalBirthday(true);
      }
    };

    waitForUserInfo();
  }, []);

  const isLoading = useSelector(
    (state: RootState) => state.loading.models.profileSlice
  );

  const logout = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("showedInstructions");
    localStorage.removeItem("@checkToken");
    dispatch.profileSlice.logout();
    navigation(APP_ROUTES.BANNER);
  };

  const { toast: orderToast } = useSelector(
    (state: RootState) => state.orderSlice
  );

  LogoutForMainAndMarketPage();

  const { shopList } = useSelector((state: RootState) => state.OtherSlice);

  return (
    <>
      <ToastContainer position="top-center" />
      {orderToast && (
        <div
          className={styles.popup}
          onClick={() => dispatch.orderSlice.isResultFunction(false)}
        >
          <div className={styles.modal}>
            <p>Спасибо за покупку !</p>
            <p>Ждем вас снова!</p>
          </div>
        </div>
      )}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "30px" }}>Loading...</p>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <div
              className={styles.headerTextId}
              style={{
                marginRight: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex" }}>
                <div className={styles.headerId}>
                  ID: <span className={styles.numberId}>{userList?.id}</span>
                </div>
                <Title title={`${userList.full_name}` || ""} fontSize="28px" />
              </div>
              <div>
                <h2 style={{ fontSize: "28px" }}>
                  {`КОЛОНИЯ: ${userList.branch_name}` || ""}
                </h2>
              </div>
            </div>
            <IconComp
              iconType="primary"
              onPress={() => setModalPassword(true)}
              icon={<PasswordIcon />}
              text="Пароль"
            />
            <IconComp
              iconType="primary"
              onPress={() => setModalBirthday(true)}
              icon={<BirthdayIcon />}
              text="Д.P."
            />
            <IconComp
              iconType="primary"
              onPress={() => setBranchModal(true)}
              icon={<Change />}
              text="Перевод"
              disabled={true}
            />
            <IconComp
              iconType="primary"
              onPress={() => logout()}
              icon={<ArrowRight />}
              text="Выход"
            />
          </div>
          <div className={styles.info}>
            <InfoComp
              title={`${priceList.balance?.toLocaleString("ru-RU")} сум`}
              text="Остаток денежных средств"
              titleColor={COLORS.green}
            />
            <InfoComp
              title={`${priceList.limit_summa?.toLocaleString("ru-RU")}` || ""}
              text="Лимит на месяц"
              titleColor={COLORS.orange}
            />
            <InfoComp
              title={`${priceList.limit_summa?.toLocaleString("ru-RU")}` || ""}
              text="Остаток по лимиту"
              titleColor={COLORS.crimson}
            />
            <InfoComp
              title="10 минут"
              text="Остаток по видеозвонкам"
              titleColor={COLORS.blue}
            />
            <InfoComp
              title="10 минут"
              text="Остаток по звонкам"
              titleColor={COLORS.darkBlue}
            />
          </div>

          <div className={styles.category}>
            {shopList.map((item) => (
              <Card
                icon={<CaseLarge />}
                text={item.name}
                onPress={() => {
                  navigation(`/${objectId}/market/` + item.id);
                  dispatch.productSlice.getProduct({
                    shop_id: item.id,
                    category_id: 0,
                  });
                }}
              />
            ))}
            <Card icon={<Mobile />} namediv={true} text="Аудиозвонки" />
            <Card icon={<VideoCall />} namediv={true} text="Видеозвонки" />
            <a
              style={{
                textDecoration: "none",
              }}
              href={`#/${objectId}/main`}
              target=""
            >
              <Card icon={<Person />} text="Омбудсман" />
            </a>
            <a
              style={{
                textDecoration: "none",
              }}
              href={`#/${objectId}/main`}
              target=""
            >
              <Card icon={<Warn />} text="Мадад" />
            </a>
          </div>

          <div className={styles.footer}>
            <Button
              style={{ width: "350px", marginBottom: "40px" }}
              onPress={() => navigation(APP_ROUTES.TRANSACTION)}
              btnSize="large"
              btnType="primary"
              title="История транзакций"
            />
          </div>
          {/* <Check /> */}
        </div>
      )}
      <BranchModal
        open={branchModal}
        onPress={() => setBranchModal(false)}
        handleClose={() => setBranchModal(false)}
      />
      <ModalPassword
        open={modalPassword}
        exitFromSystem={isCorrectPass}
        onPress={() => setModalPassword(false)}
        userInfo={userList}
      />
      <ModalBirthday
        open={modalBirthday}
        exitFromSystem={isBirthday}
        onPress={() => setModalBirthday(false)}
        userInfo={userList}
      />
    </>
  );
};

export default observer(MainView);
