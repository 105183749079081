import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import Carousel from "nuka-carousel";
import { baseUrl } from "../../contants/API";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../router/Route";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import img from "../../assets/images/banner.png";
import LogoName from "../LogoName/logoName";

const BannerForWindow = () => {
  const dispatch = useDispatch<Dispatch>();
  const branch_id = localStorage.getItem("branch_id");
  const navigate = useNavigate();
  const { sliderListNotToken } = useSelector(
    (state: RootState) => state.OtherSlice
  );

  useEffect(() => {
    const setObjectId = () => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const objectId =
        url.hash && new URLSearchParams(url.hash.substring(1)).get("obj");
      const cameraToken =
        url.hash && new URLSearchParams(url.hash.substring(1)).get("cam");
      const monoblockId =
        url.hash && new URLSearchParams(url.hash.substring(1)).get("monitor");

      if (
        objectId !== null &&
        !localStorage.getItem("object_id") &&
        cameraToken !== null &&
        !localStorage.getItem("cameraToken")
      ) {
        localStorage.setItem("object_id", objectId);
        localStorage.setItem("cameraToken", cameraToken);
      }

      if (monoblockId !== null && !localStorage.getItem("monitor_id")) {
        localStorage.setItem("monitor_id", monoblockId);
      }
    };

    const fetchSliderData = () => {
      const objectId = localStorage.getItem("object_id");

      switch (objectId) {
        case "99":
        case "13":
        case "14":
        case "23":
          dispatch.OtherSlice.getSliderNotToken("1");
          break;
        case "2":
          dispatch.OtherSlice.getSliderNotToken("8");
          break;
        case "3":
          dispatch.OtherSlice.getSliderNotToken("7");
          break;
        default:
          dispatch.OtherSlice.getSliderNotToken("12");
          break;
      }
    };

    setObjectId();
    fetchSliderData();
  }, [dispatch]);

  const handleLogoClick = () => navigate(APP_ROUTES.BANNER);

  return (
    <>
      {!branch_id ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleLogoClick}
        >
          <LogoName />
        </div>
      ) : (
        <Swiper
          slidesPerView={1}
          loop
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          navigation={{
            prevEl: ".main-banner__slider-prevbutton",
            nextEl: ".main-banner__slider-nextbutton",
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          style={{ height: "100vh" }}
        >
          {sliderListNotToken?.map((item, index) => (
            <SwiperSlide key={index}>
              <div style={{ height: "100vh" }}>
                <img
                  onClick={handleLogoClick}
                  style={{
                    height: "93%",
                    width: "100vw",
                    objectFit: "contain",
                  }}
                  src={`${baseUrl}/${item.path}`}
                  alt="Banner"
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    background: "red",
                    width: "100%",
                    padding: "0 20px",
                    height: "7%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "25px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {item.title ??
                      "Smart Dunyodan tez kunda yangiliklarni kuting!"}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default BannerForWindow;
